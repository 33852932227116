// FIXME: localstorage item name
const getAuthToken = () => localStorage.getItem('lcpToken');

const authInterceptor = (config) => {
  const Config = config;
  Config.headers.Authorization = getAuthToken();
  return Config;
};

export default authInterceptor;
