import axios from 'axios';

import authInterceptor from './interceptors/auth.interceptor';
import responseInterceptor from './interceptors/response.interceptor';
import errorInterceptor from './interceptors/error.interceptor';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
});

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
