<template>
  <div class="fs-wrapper">
    <div class="card thanks">
      <div class="card-content">
        <h4>{{ $t("thankyou_registration") }}</h4>
        <p>
          Canlı yayın linki ve etkinlik şifresi e-posta adresinize,
          “2024 İletişim Kampanyası Lansmanı” başlığıyla gönderilmiştir.
        </p>
        <p style="color: #f14668" v-html="$t('link_warning')"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.fs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  height: 100vh;
  width: 100vw;

  p {
    margin-bottom: 16px;
  }
  .card-content {
    max-width: 720px;
  }

  h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 56px;
  }
}
</style>
