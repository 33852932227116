/* eslint-disable */
import { required, numeric, email, alpha_spaces } from "vee-validate/dist/rules";

import httpClient from "@/api/client";
import { extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import tr from "vee-validate/dist/locale/tr.json";
import es from "vee-validate/dist/locale/es.json";
import de from "vee-validate/dist/locale/de.json";
import fr from "vee-validate/dist/locale/fr.json";
import ro from "vee-validate/dist/locale/ro.json";

// Install English and Arabic locales.
localize({
  en,
  tr,
  es,
  de,
  fr,
  ro,
});

const turkishToUpper = function (string) {
  const letters = {
    i: "İ",
    ş: "Ş",
    ğ: "Ğ",
    ü: "Ü",
    ö: "Ö",
    ç: "Ç",
    ı: "I",
  };
  const str = string.replace(/(([iışğüçö]))/g, (letter) => letters[letter]);
  return str.toUpperCase();
};

// eslint-disable camelcase
/* import {

  required, email, numeric, confirmed, alpha_spaces,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: $t('Bu alan zorunludur.',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match',
});
*/

extend("required", required);
extend("numeric", numeric);
extend("email", email);
extend("alpha_spaces", alpha_spaces);
extend("len", {
  validate(value, args) {
    return `${value}`.length === parseInt(args.length, 10);
  },
  params: ["length"],
});
extend("min", {
  validate(value, args) {
    return `${value}`.length >= parseInt(args.length, 10);
  },
  params: ["length"],
});
extend("max", {
  validate(value, args) {
    return `${value}`.length <= parseInt(args.length, 10);
  },
  params: ["length"],
});

extend("tcid", {
  validate(value) {
    const kimlikno = value.toString();
    const uzunluk = /^[0-9]{11}$/.test(kimlikno);
    let toplamX1 = 0;
    for (let i = 0; i < 10; i += 1) {
      toplamX1 += Number(kimlikno.substr(i, 1));
    }
    const kural1 = (toplamX1 % 10).toString() === kimlikno.substr(10, 1);
    let toplamY1 = 0;
    let toplamY2 = 0;
    for (let i = 0; i < 10; i += 2) {
      toplamY1 += Number(kimlikno.substr(i, 1));
    }
    for (let i = 1; i < 9; i += 2) {
      toplamY2 += Number(kimlikno.substr(i, 1));
    }
    const kural2 = ((toplamY1 * 7 - toplamY2) % 10).toString() === kimlikno.substr(9, 1);

    const kural3 = kimlikno.substr(0, 1) !== "0";

    return uzunluk && kural1 && kural2 && kural3;
  },
});

extend("tcidn", {
  async validate(tcId, registerFormData) {
    if (!tcId) {
      return "Lütfen bu alanı doldurunuz.";
    }
    const num = Number.parseInt(tcId, 10);

    if (!Number.isInteger(num)) {
      return "TC Kimlik Numarası sadece rakamlardan oluşmalıdır.";
    }
    if (num.toString().length !== 11) {
      return "TC Kimlik Numarası 11 Haneli olmalıdır.";
    }
    if (
      registerFormData[0] === undefined ||
      registerFormData[1] === undefined ||
      registerFormData[2] === undefined
    ) {
      return "TC Kimlik doğrulaması için Lütfen Ad, Soyad ve doğum yılı bilgilerinizi giriniz";
    }
    const data = {
      TCKimlikNo: tcId,
      Ad: turkishToUpper(registerFormData[0].trim()),
      Soyad: turkishToUpper(registerFormData[1].trim()),
      DogumYili: registerFormData[2].trim(),
    };
    return httpClient.post("checkTcId", data).then(
      (res) => {
        if (res.status === 200) {
          return true;
        }
        return "TC Kimlik Numaranız girdiğiniz bilgiler ile uyuşmuyor. Lütfen kontrol ediniz.";
      },
      (err) => {
        if (err.response.status === 422) {
          return "TC Kimlik Numaranız girdiğiniz bilgiler ile uyuşmuyor. Ad, Soyad ve doğum yılı bilgilerinizi nüfus cüzdanınızada gözüktüğü şekilde giriniz.";
        }
        if (err.response.status === 400) {
          return "TC Kimlik doğrulaması için Lütfen Ad, Soyad ve doğum yılı bilgilerinizi giriniz";
        }
        return "TC Kimlik doğrulama işlemi şu anda gerçekleştirilemiyor";
      }
    );
  },
});
