// interceptor to catch errors
const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    console.error('Network/Server error');
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      console.error('Nothing to display', 'Data Not Found');
      break;

    case 401: // authentication error, logout the user
      console.error('Please login again', 'Session Expired');
      // FIXME: logout logic
      // add logout logic here (localstorage, router.push etc.)
      break;

    default:
      console.error(error.response.status, error.message);
      console.error('Server Error');
  }
  return Promise.reject(error);
};

export default errorInterceptor;
